import React from 'react'

// Components
import Layout from 'components/layout/Layout'

const NotFoundPage = () => (
  <Layout>
    <div className="container py-5">
      <h1>404</h1>
      <p>Pagina niet gevonden.</p>
    </div>
  </Layout>
)

export default NotFoundPage
